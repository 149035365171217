// COLORS
/////////

$gray-200: #e9ecef !default;
$gray-400: #ced4da !default;
$gray-800: #676766 !default;
$blue: #0f8cc6; //$blue:    #007bff !default;
$red: #ff5a5f; //$red:     #dc3545 !default;
$light-red: #ef5f5f;

// New theme colors
////////

$pale-blue: #d9e0fb;
$clear-blue: #2255ff;
$azure-blue: #0088ff;
$bright-sky-blue: #15bdff;
$light-blue: #79aec8;
$ice-blue: #e7f4fa;
$off-white: #F8F9FA;
$light-grey: #f3f3f1;
$grey-platinum: #EBEBEB;
$pale: #e7e7e3;
$pale-darker: #bfbfbb;
$medium-grey: #676766;
$darker-grey: #505050;
$black: #001835;
$body-color: $medium-grey;
$blue-gradient: linear-gradient(258deg, #33B7EA 0%, #132D80 100%, #224F9D 100%);
$blue-gradient-reverse: linear-gradient(258deg, #224F9D 0%, #33B7EA 100%, #132D80 100%);
$red-gradient: linear-gradient(90deg,#C1272D,#D4145A 50%,#D4145A);
$pink-podcast: #D4145A;
$white: #fff !default;
$royal-blue: #3754fb;
$sapphire: #3658a5;
$electric-blue: #2653fc;
$very-light-blue: #00CDFD;
$cyan: #51ADB9;
$light-cyan: rgba(81, 173, 185, 0.19);
$yellow-gold: #fccd1d;

$clear-blue: #4ccbf5;
$clear-blue-svg: invert(66%) sepia(72%) saturate(572%) hue-rotate(164deg) brightness(101%) contrast(92%);
$pro-blue: #001836;
$home-blue: #2ebaff;
$home-blue-svg: invert(56%) sepia(94%) saturate(1056%) hue-rotate(170deg) brightness(100%) contrast(102%);
$testimony-blue: #2653fb;  // TODO merge with $electric-blue ? same colors !
$testimony-blue-op20: rgba(#2653fb,0.2);
$testimony-blue-op50: rgba(#2653fb,0.5);
$collectivity-violet: #4030a4;
$collectivity-violet-svg: invert(12%) sepia(83%) saturate(3232%) hue-rotate(245deg) brightness(102%) contrast(90%);
$enterprise-blue: #1a4eff;

$part-emp-color: $cyan;
$presta-color: $electric-blue;

$service-offer-color: $testimony-blue;
$job-offer-color: $electric-blue;

$green: #51E584;
$light-yellow: #FFE1A8;
$orange: #E5A651;

// Menu
///////
$menu-dark-blue: $black;
$menu-active-blue: $electric-blue;
$menu-width-collapsed: 45px;
$menu-radius-collapsed: 15px;
$menu-width-not-collapsed: 320px;
$menu-radius-not-collapsed: 28px;
$menuheader-desk-height: 80px;
$menuheader-mobile-height: 68px;
$submenuheader-height: 63px;
$allmenus-height: 131px;

// Prime options colors
///////////////////////
$prime-blue: #71d9ff;
$peace-blue: #0d89fa;
$peace-plus-blue: #3450f9;


// Options colors
/////////

$premium-option-color: #25b3ff;
$visibility-color: $electric-blue;
$prime-color: $cyan;
$checked-identity-color: #7de08c;


// Fonts
////////

$small-font-size: 85%;
$font-family-sans-serif: "Montserrat",-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;
$font-family-icon: ala-icons, $font-family-sans-serif;
$font-size-base: .875rem;
$line-height-base: 1.6;

// Headings
///////////

$headings-line-height: normal;
$headings-font-weight: 600;
$headings-color: $gray-800;
$h1-font-size: 2.5rem;
$h2-font-size: 1.875rem;
$h3-font-size: 1.25rem;
$h4-font-size: 1rem;
$h5-font-size: 1rem;
$h6-font-size: 1rem;

$form-group-margin-bottom: 2rem;

// Badges
//////////
$badge-padding-y: .375rem;
$badge-padding-x: .4rem;

// Buttons
/////////
$btn-block-spacing-y: 1.5rem;

// Forms
////////
$input-color: $medium-grey;
$input-border-color: #9b9b99;


// Breadcrumbs
////////
$breadcrumb-bg: white;
$breadcrumb-padding-y: 1.5rem;
$breadcrumb-active-color: $medium-grey;
$breadcrumb-item-padding: .1rem;

// Pagination
/////////
$pagination-color: $black;
$pagination-active-color: $white;
$pagination-active-bg: $electric-blue;
$pagination-active-border-color: transparent;
$pagination-border-width: 0px;
$pagination-border-color: transparent;
$pagination-hover-color: $white;
$pagination-hover-bg: $black;

// Categories
/////////////
$home-services-bg: url('/ui/static/img/categories/domicile-bg.jpg');
$home-services-small: url('/ui/static/img/categories/domicile.jpg');
$childcare-bg: url('/ui/static/img/categories/enfants-bg.jpg');
$childcare-small: url('/ui/static/img/categories/enfants.jpg');
$vulnerable-individuals-bg: url('/ui/static/img/categories/vulnerable-individuals-bg.jpg');
$vulnerable-individuals-small: url('/ui/static/img/categories/vulnerable-individuals.jpg');
$school-tutoring-bg: url('/ui/static/img/categories/soutien-scolaire-bg.jpg');
$school-tutoring-small: url('/ui/static/img/categories/soutien-scolaire.jpg');
$music-bg: url('/ui/static/img/categories/musique-bg.jpg');
$music-small: url('/ui/static/img/categories/musique.jpg');
$senior-support-bg: url('/ui/static/img/categories/aide-aux-seniors-bg.jpg');
$senior-support-small: url('/ui/static/img/categories/aide-aux-seniors.jpg');
$disabled-support-bg: url('/ui/static/img/categories/aide-handicap-bg.jpg');
$disabled-support-small: url('/ui/static/img/categories/aide-handicap.jpg');
$beauty-sector-bg: url('/ui/static/img/categories/beaute-bg.jpg');
$beauty-sector-small: url('/ui/static/img/categories/beaute.jpg');
$computing-bg: url('/ui/static/img/categories/informatique-bg.jpg');
$computing-small: url('/ui/static/img/categories/informatique.jpg');
$sports-dance-bg: url('/ui/static/img/categories/sport-et-danse-bg.jpg');
$sports-dance-small: url('/ui/static/img/categories/sport-et-danse.jpg');
$home-repairs-bg: url('/ui/static/img/categories/travaux-domicile-bg.jpg');
$home-repairs-small: url('/ui/static/img/categories/travaux-domicile.jpg');
$language-classes-bg: url('/ui/static/img/categories/cours-de-langue-bg.jpg');
$language-classes-small: url('/ui/static/img/categories/cours-de-langue.jpg');
$administrative-positions-bg: url('/ui/static/img/categories/postes-administratifs-bg.jpg');
$administrative-positions-small: url('/ui/static/img/categories/postes-administratifs.jpg');
$medical-small: url('/ui/static/img/categories/sante-et-medical.jpg');
$medical-bg: url('/ui/static/img/categories/sante-et-medical-bg.jpg');
$company-services-small: url('/ui/static/img/categories/services-aux-entreprises.jpg');
$company-services-bg: url('/ui/static/img/categories/services-aux-entreprises-bg.jpg');

// Modals
/////////
$zindex-modal: 2000;


// Unbounce
///////////

$unbounce-blue: #061132;
$unbounce-yellow: #f5cb44;
$unbounce-grey: #a3a3a3;
$unbounce-red: #E56663;


// Box-shadow
/////////////
$default-shadow: 0 4px 6px #00000029;
$default-inside-shadow: inset 0 3px 6px #00000029;


// MyAladom Menu
////////////////
$transition-time: 500ms;
$transition-short-time: 300ms;

// Scrollbar width
//////////////////
:root {
  --scrollbar-width: 0px;
}

// Inbox
////////
$inbox-presta: #D9E1FF;
$inbox-part-emp: #A2D5E2;
$inbox-pro: #00000029;

// Animated
///////////
@-webkit-keyframes ala-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes ala-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

// Popover
//////////
$zindex-popover: 10000;