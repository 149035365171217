@mixin compat-display-flex() {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

@mixin compat-align-items($value) {
  -webkit-box-align: $value;
  -moz-box-align: $value;
  -webkit-align-items: $value;
  -ms-flex-align: $value;
  align-items: $value;
}

@mixin compat-flex($value) {
  -webkit-box-flex: $value;
  -moz-box-flex: $value;
  -webkit-flex: $value;
  -ms-flex: $value;
  flex: $value;
}

@mixin grayscale($value) {
  filter: #{"grayscale(#{$value})"} contrast(90%) opacity(90%);
  -webkit-filter: #{"grayscale(#{$value})"} contrast(90%) opacity(90%);
}

@mixin modal-input() {
  font-size: 1.125rem;
  letter-spacing: -1px;
}

@mixin option-box($value) {
  background-color: $value;

  &:before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 5px 5px 5px;
    border-color: transparent transparent $value transparent;
    position: absolute;
    top: -5px;
    right: calc(50% - 5px);
  }
}

@mixin btn-color($value) {
  background-color: $value;
  font-weight: 400;
  color: white;
  letter-spacing: 0;
  box-shadow: $default-shadow;
  border-radius: 8px;
  @include media-breakpoint-down(sm) {
    border-radius: 40px;
    padding: .75rem;
    font-weight: 300;
    font-size: .9rem;
  }

  &[type=submit] {
    font-weight: 600;
  }

  &:hover {
    color:white;
    background-color: darken($value, 10%);
  }
}

@mixin gradient-box() {
  background-image: $blue-gradient;
  color: white;
  font-size: 1.125rem;
  letter-spacing: -.5px;
  line-height: 1.45rem;
  padding: 2rem;

  h2 {
    color: white;
  }
}

@mixin offer-provider-picture($value) {
  transition: $transition-time ease-in-out;
  border-radius: 50%;
  background-color: white;
  overflow: hidden;
  width: $value;
  height: $value;
  display: flex;
  position: relative;
  flex-shrink: 0;
}

@mixin full-header-no-filters() {
  @include media-breakpoint-up(md) {
    border: none;
  }
  z-index: 1001;
  position: fixed;
  top: 0;

  &.not-home {
    background-color: white;
    box-shadow: 0 1px 6px 0 rgba(32,32,95,0.28);
  }

  .navbar{
    min-width: 0;
    flex-grow: 1;
    @include media-breakpoint-up(lg) {
      padding: 0;
    }
  }

  .logo-aladom {
    background-image: url('/ui/static/img/logotype-blanc-small.png');
    height: 89px;
    width: 322px;
    background-size: cover;

    &:hover {
      filter: brightness(85%);
    }
  }

  .nav-link {
    &.nav-link-highlight {
      display: block;
      @include media-breakpoint-up(lg) {
        color: $black;
        border: 2px solid $testimony-blue;
        border-radius: 200px;
        margin-top: 2px;
        &:hover {
          color: $white !important;
          background: $testimony-blue !important;
        }
      }
    }
  }

  li:not(.aladom-home-item) .nav-link:hover {
    color: $testimony-blue;
  }

  .user-name-header {
    color: $white;
  }
}

@mixin select2-placeholder() {
  content: "";
  position: absolute;
  left: 10px;
  top: 0;
  bottom: 0;
}

@mixin sticky-base {
  position: fixed;
  top: 0;
  background-color: white;
  z-index: 2000;
  border-bottom: 1px solid #e7e7e3;
  box-shadow: 0 1px 6px 0 rgba(32,32,95,0.28);
  width: 100%;
}

@mixin sticky-header-full {
  width: 100%;

  #page-header-full-user-box {
    padding-bottom: 0;
    margin-top: .6rem;
  }

  .navbar-light form.offer-search-form {
    display: flex;
    padding-top: .8rem;
    padding-bottom: .8rem;
  }
}

@mixin search-icon {
  position: absolute;
  right: 35px;
  top: calc(50% - .6rem);
  color: $testimony-blue;
  font-size: 1.2rem;
  width: 19.2px;
  height: 19.2px;
}

@mixin banner {
  -webkit-clip-path: ellipse(140% 100% at 50% 0);
  clip-path: ellipse(140% 100% at 50% 0);
  color: $white;
  font-size: 1rem;
  letter-spacing: -0.4px;
  position: relative;
  @include media-breakpoint-down(md) {
    clip-path: inherit;
    -webkit-clip-path: inherit;
  }
  h1, h2 {
    color: $white;
    letter-spacing: 0;
  }

  p {
    font-weight: 500;
    font-size: 1.375rem;
    line-height: normal;
  }

  a:not(.btn) {
    color: $white;
    font-weight: 600;
    text-decoration: underline;
  }
}

@mixin info-logo($color) {
  position: absolute;
  top: -10px;
  left: 5px;
  width: 18px;
  height: 18px;
  line-height: 18px;
  border-radius: 50%;
  border: 1px solid $color;
  color: $color;
  font-size: 12px;
  cursor: pointer;
}

@mixin pressed-btn {
  box-shadow: 0 0 0 #00000000, inset 0 10px 6px #0000004D;
}
