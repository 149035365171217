@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "mixins";
@import "vars";

#home {
  overflow: hidden;
  padding-bottom: 5rem;
  @include media-breakpoint-up(lg) {
    padding-bottom: 20rem;
  }

  h1 {
    color: $black;
    font-size: 1.9rem;
    @include media-breakpoint-down(lg) {
      font-size: 1.25rem;
    }
  }

  h2 {
    margin-bottom: 3rem;
    color: $black;
    font-size: 1.9rem;
    font-weight: 700;

    @include media-breakpoint-down(lg) {
      font-size: 1.25rem;
    }
  }

  .hero {
    flex-direction: column;
    justify-content: start;
    background-image: url('/ui/static/img/home_header_img.webp');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
    overflow: visible;
    height: 100vh;
    max-height: unset;
    margin-bottom: 150px;
    padding-top: 6rem;
    @include media-breakpoint-down(sm) {
      min-height: 600px;
      height: auto;
      margin-bottom: 0px;
      padding-top: 80px;
      display: block;
      box-shadow: 0px 6px 10px #0000006D;
      background-position: top;
      background-size: contain;
      background-color: #ededed;
    }

    .hero-box-heading {
      @include media-breakpoint-down(md) {
        padding: 0 50px;
      }
    }

    .row {
      z-index: 1;
      width: 100%;
      padding: 100px;
      @include media-breakpoint-down(md) {
        padding: 50px;
        margin: 0;
      }
      @include media-breakpoint-down(xs) {
        padding: 0px;
      }
    }

    img.home-ribbon-marine {
      position: absolute;
      bottom: -300px;
      min-width: 1900px;
      width: 100%;
      @include media-breakpoint-down(sm) {
        display: none;
      }
      @media (min-height: 1000px) {
        bottom: -29%;
      }
    }

    .aladom-pro {
      position: absolute;
      right: 0;
      padding-right: 55px;
      padding-bottom: 100px;
      bottom: 0;
      text-align: right;
      display: flex;
      justify-content: end;
      align-items: end;
      @include media-breakpoint-down(sm) {
        background-color: $black;
        position: relative;
        height: auto;
        padding: 20px 50px;
      }

      .pro-box {
        max-width: 600px;
        z-index: 90;
        div {
          text-align: left;
        }
        h2 {
          text-align: right;
        }
        @include media-breakpoint-down(lg) {
          max-width: 300px;
          padding: 2rem 0.5rem;
          h2, div {
            text-align: right;
            @include media-breakpoint-down(sm) {
              font-size: 1.25rem;
              padding-bottom: 1rem;
            }
          }
        }
      }

      img {
        margin-bottom: 50px;
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }

      h2 {
        color: white;
        font-weight: 800;
        font-size: 1.9rem;
        text-align: right;
        @include media-breakpoint-down(md) {
          font-size: 1.1rem;
        }
      }
    }
  }

  .mt-10 {
    margin-top: 4rem;
    @include media-breakpoint-up(sm) {
      margin-top: 5rem;
    }
    @include media-breakpoint-up(lg) {
      margin-top: 10rem;
    }
  }
}

.home-search {
  max-width: 500px;
  @include media-breakpoint-down(md) {
    width: 100%;
    max-width: initial;
    display: flex;
    justify-content: center;
  }
  @include media-breakpoint-only(sm) {
    padding-bottom: 50px;
  }


  #home-search-form {
    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    &-row {
      @include media-breakpoint-down(sm) {
        position: relative;
      }
    }

    .home-text-search {
      position: relative;
      @include media-breakpoint-down(sm) {
        margin: 0 45px 50px;
        position: initial;
      }

      i {
        @include search-icon();
      }
    }
  }
}


// Header
/////////
#page-header.home {
  background-color: white;
  @include full-header-no-filters();
  &:not(.sticky) .navbar {
    .navbar-brand {
      @include media-breakpoint-down(lg) {
        width: 77px;
      }
    }
  }
}


// Cards
/////////
#home .category-card {
  border: none;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 10px 6px #0000004D, inset 0 0 0 #00000000;
  height: 150px;
  transition: box-shadow 600ms ease-in-out;
  @include media-breakpoint-down(sm) {
    height: 120px;
  }
  @include media-breakpoint-down(xs) {
    height: 100px;
    margin-bottom: 1rem;
  }

  &:hover {
    @include pressed-btn();
    img.card-header-bg {
      transform: scale(1.05);
    }
  }

  .card-header {
    background: none;
    height: 100%;
    @include media-breakpoint-down(xs) {
      padding: .5rem;
    }
  }

  img {
    z-index: -1;
    transition:  transform 600ms ease-in;
    width: 50px;

    &.icon-small {
      width: 60px;
    }
  }

  img.card-header-icon {
    @include media-breakpoint-down(sm) {
      width: 45px;
    }
    @include media-breakpoint-down(xs) {
      width: 30px;
    }
  }

  h3 {
    line-height: 1.5rem;
    font-size: 1.5rem;
    @include media-breakpoint-down(sm) {
      font-size: 1.3rem;
      line-height: 1.3rem;
    }
    @include media-breakpoint-down(xs) {
      font-size: 1rem;
      line-height: 1rem;
    }
  }

  &.quotation-card {
    color: $black;
    font-weight: 700;
    text-transform: uppercase;
    height: auto;
    box-shadow: 0px 3px 6px #0000004D;
    line-height: 1rem;
    @include media-breakpoint-down(md) {
      max-width: 220px;
    }
    @include media-breakpoint-down(sm) {
      margin-top: 3rem;
    }
  }

  &.more-categories {
    position: relative;
    display: block;
    color: $testimony-blue;
    font-weight: 800;
    text-transform: uppercase;
    line-height: 1.2;

    img {
      position: absolute;
      bottom: 14px;
      left: 0;
      width: 100%;
      @include media-breakpoint-down(sm) {
        bottom: 3px;
      }
    }

    &:hover {
      text-decoration: none;
      color: $testimony-blue;
    }
  }
}


// Sections
/////////
#home {
  .bottom-home-ribbon {
    position: absolute;
    width: 100%;
    z-index: -1;
    left: 0;
    right: 0;

    img {
      min-width: 1800px;
    }
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  .bottom-home {
    margin-top: 7rem;
    @include media-breakpoint-up(lg) {
      margin-top: 17rem;
      border: 3px solid $black;
      background-color: white;
      border-radius: 30px;
      padding: 3.5rem 1rem 3.5rem 3.5rem;
    }
    @include media-breakpoint-down(md) {
      img {
        width: 60px;
        height: 60px;
      }
    }

    .aladom-mag {
      color: $black;
      font-weight: 500;
      @include media-breakpoint-up(lg) {
        font-weight: 600;
      }
      @include media-breakpoint-down(sm) {
        line-height: 1.25rem;
      }
    }

    img {
      margin-top: -5px;
    }
    .tags-cloud-section img {
      margin-top: -10px;
    }

    .bottom-home-title {
      margin-bottom: 2.5rem;
    }
  }

  h2 {
    font-weight: 700;
    font-size: 1.9rem;
    @include media-breakpoint-down(sm) {
      font-size: 1.25rem;
    }
  }

  .city-item {
    color: $black;
    font-weight: 100;
    text-transform: uppercase;
    font-size: 1.3rem;
    display: grid;
    justify-items: center;
    &:hover {
      text-decoration: none;
    }
    @include media-breakpoint-down(sm) {
      font-size: .9rem;
      font-weight: 300;
    }

    .section-city h2 {
      margin-bottom: 4rem;
    }

    .city-img {
      width: 150px;
      height: 150px;
      @include media-breakpoint-down(sm) {
        width: 100px;
        height: 100px;
        border-radius: 20px;
      }
      box-shadow: 0px 3px 6px $pale;
      border-radius: 37px;
      margin-bottom: 1rem;
      overflow: hidden;
      background-color: $testimony-blue;

      img {
        width: 100%;
        mix-blend-mode: luminosity;
        transition: all 800ms ease-in;
        cursor: pointer;
        &:hover {
          mix-blend-mode: normal;
        }
      }
    }
  }
  .city-all {
    color: $black;
    text-align: center;
    padding-top: 1.5rem;
    font-weight : 700;
    margin-bottom: 5rem;
    @include media-breakpoint-down(sm) {
      padding-top: .8rem;
      font-weight : 500;
    }
  }

  .tags-cloud-section {
    padding-top: 1rem;
    @include media-breakpoint-down(sm) {
     margin-top: 5rem;
    }

    .tags-cloud-title {
      display: flex;
      align-items: center;
    }
    .tags-cloud {
      margin-left: -.7rem;
      overflow: visible;
      @include media-breakpoint-down(sm) {
        display: flex;
        flex-wrap: wrap;
        margin-left: 0;
      }
      .badge {
        margin: 0 0 1.375rem 0;
        @include media-breakpoint-down(sm) {
          margin: 0;
        }
      }
    }

    @include media-breakpoint-up(lg) {
     border-left: 3px solid $black;
     padding-top: 0;
     padding-left: 50px;
    }

    .cloud-item {
      font-weight: 700;
      margin: .25rem;
      padding: 1rem;
      text-transform: uppercase;

      @include media-breakpoint-down(sm) {
        font-weight: 500;
        white-space: pre-wrap;
        white-space: -moz-pre-wrap;
        white-space: -pre-wrap;
        white-space: -o-pre-wrap;
        align-items: center;
        justify-content: center;
        display: flex;
        padding: 1rem;
        a {
          line-height: .75rem;
          padding: .25rem !important;
          background-color: unset;
        }
      }
    }
  }

  #news-home {
    margin-bottom: 7rem;
    @include media-breakpoint-down(md) {
      margin-bottom: 0;
      padding-top: 4rem;
      margin-top: 4rem;
      box-shadow: 0px -6px 10px #0000002D;
    }
    @include media-breakpoint-down(sm) {
      margin-top: 0;
    }

    h2 {
      font-weight: 700;
      font-size: 1.9rem;
      margin-top: 6rem;
      margin-bottom: 3rem;
      text-align: center;
      @include media-breakpoint-down(sm) {
        font-size: 1.25rem;
        margin-top: 1rem;
        text-align: left;
      }
    }

    .section-separator {
      margin-right: -1rem;
      margin-left: -7rem;
      img {
        top: -200px;
      }
    }
  }

  .slider-news {
    .list-item-card {
      background: none;

      .items-list {
        font-weight: 400;
        text-align: left;
        padding: 30px 0;
        font-size : .8rem;
      }
    }
    a:hover {
      text-decoration: none;
    }
    h3.headline {
      font-weight: 900;
      margin-bottom: 3rem;
    }
    p.description {
      margin: 0;
    }
  }

  .category-section {
    @include media-breakpoint-up(md) {
      padding-top: 3rem;
    }

    h2 {
      font-weight: 700;
      font-size: 1.9rem;
      @include media-breakpoint-down(sm) {
        font-size: 1.25rem;
        span {
          display: block;
          font-weight: 500;
        }
      }
    }
    .card-columns {
      @include media-breakpoint-down(xs) {
        column-count: 2;
      }
    }
  }
  .list-item-card-payment {
    border-radius: 15px;
    font-size: 1.25rem;
    font-weight: 500;
    @include media-breakpoint-down(sm) {
      font-size: initial;
    }
    .box-white {
      width: 100px;
      height: 100px;
      @include media-breakpoint-down(sm) {
      width: 70px;
      height: 70px;
      }
      i {
        font-size: 3rem;
        @include media-breakpoint-down(sm) {
        font-size: 2rem;
        }
      }
    }
  }
}
